import React from 'react';
import styles from "./private.module.scss";
import ParallaxText from '../Components/ParallaxText';
import TitleAnimation from '../Components/TitleAnimation';

function PrivatePage() {
  return (
    <div className={styles.private}>

   
        <TitleAnimation />

        {/* Bloc pour les vidéos YouTube */}
        <div className={styles.videoWrapper}>
            <h1>MUHAMMAD ALI THE GREATEST </h1>
            <video className={styles.videoplayer} controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()} poster="/img/ali.jpg">
        <source src="https://medias-distribution.lab.arte.tv/movies/full/B2B_EDI-008675-VE-PGM-VE.mp4" type="video/mp4" />
        </video>
        </div>
        <div className={styles.videoWrapper}>
        <h1>THE FRENCH</h1>
        <video className={styles.videoplayer} controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()} poster="/img/thefrench.jpg">
        <source src="https://medias-distribution.lab.arte.tv/movies/full/FULL_EDI-006247-VF-VOF_1.mp4" type="video/mp4"  />
        </video>
         
        </div>

    </div>
  );
};

export default PrivatePage;
