import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import styles from "./ParallaxText.module.scss"
function ParallaxText({ children, baseVelocity = 100, outline = false }) {
    const scrollerRef = useRef(null);
    const textStyle = outline ? { color: "transparent", WebkitTextStroke: "1px #faebd7" } : {};

    useEffect(() => {
        let directionFactor = 1;
        let x = 0;

        const updatePosition = () => {
            x += directionFactor * baseVelocity;
            x = x % window.innerWidth; // Adapt this based on your requirements
            gsap.set(scrollerRef.current, { x: -x });
        };

        const interval = setInterval(updatePosition, 50);

        const onScroll = () => {
            const scrollVelocity = window.scrollY - lastScrollY;
            directionFactor = scrollVelocity >= 0 ? 1 : -1;
            lastScrollY = window.scrollY;
        };

        let lastScrollY = window.scrollY;
        window.addEventListener('scroll', onScroll);

        return () => {
            clearInterval(interval);
            window.removeEventListener('scroll', onScroll);
        };
    }, [baseVelocity]);

    return (
        <div className={styles.parallax}>
            <div className={styles.scroller} ref={scrollerRef}>
                <span style={textStyle}>{children}</span>
                <span style={textStyle}>{children}</span>
                <span style={textStyle}>{children}</span>
                <span style={textStyle}>{children}</span>
                <span style={textStyle}>{children}</span>
                <span style={textStyle}>{children}</span>
                <span style={textStyle}>{children}</span>
                <span style={textStyle}>{children}</span>
            </div>
        </div>
    );
}

export default ParallaxText;
