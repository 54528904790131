import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import splitting from 'splitting';
import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';
import styles from "./TitleAnimation.module.scss"

const TitleAnimation = () => {
    const titleRef = useRef(null);

    useEffect(() => {
        // Initialisation de Splitting pour diviser le texte
        const result = splitting({ target: titleRef.current, by: "chars" });
        const chars = result[0].chars;

        // GSAP fromTo Animation pour chaque lettre
        gsap.fromTo(chars, {
            y: "222000%", // Départ au-dessus de l'écran
            opacity: 0,
            scale: 9,
        }, {
            y: "0%", // Arrivée à la position originale
            opacity: 1, // Devenant pleinement opaque
            scale: 1,

            duration: 2.0,
            ease:     "power4.inOut"
            , // Effet de rebond pour un atterrissage plus naturel
            stagger: 0.3, // Délai progressif pour chaque lettre
            delay: 2,
        });
    }, []);


    return (
        <h1 ref={titleRef} data-splitting className={styles.headerTitle}>
            <span className={styles.william}>WILLIAM</span> <span className={styles.klein}>KLEIN</span>
        </h1>
    );
};

export default TitleAnimation;
