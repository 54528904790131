import React, { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import styles from "./Landing.module.scss";
import ParallaxText from '../Components/ParallaxText';
import TitleAnimation from '../Components/TitleAnimation';


const Landing = () => {
    const imageUrl = process.env.PUBLIC_URL + '/img/patchwork-william-klein.jpg';
    const imageRef = useRef(null);
    const BlocBas = useRef()

    useEffect(() => {
      if (imageRef.current) {
        gsap.to(imageRef.current, {
          width: '45%', // Exemple d'animation
          duration: 1,
          ease: 'power3.inOut',
          delay: 1.5,
        });
      }
      if (BlocBas.current) {
           gsap.fromTo(BlocBas.current, { width: '100%', y: 1000 }, { width: '100%', y: 0, duration: 1, delay: 4 });
      }
    }, []);
    
    return (
        <div className={styles.landing}>

            <div className={styles.ParallaxTextLanding}>
                        <ParallaxText  outline={false} baseVelocity={2}>Coming Soon...</ParallaxText>
                        </div>
            <header className={styles.header}>

            <TitleAnimation />
            </header>


            <div className={styles.BlocBas}>
            <div className={styles.citeBloc} ref={BlocBas}>
                <div className={styles.citation}>
                    "Anything goes. <br></br>No rules, no limits."
                </div>
                <div className={styles.contactBloc}>
                <div className={styles.contact}>
                For all enquiries concerning William Klein Studio, <br></br>please contact:
                </div>
                <div className={styles.contactMail}>
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16.4666" cy="16.4662" r="15.8999" transform="rotate(42.606 16.4666 16.4662)" fill="#9E0F0F"/>
                </svg> <a href="mailto:contact@williamklein.fr">contact@williamklein.fr</a>                
                </div>
                </div>
            </div>

            <div className={styles.imgBloc} ref={imageRef}>
            <img src={imageUrl} alt="Description" />

            </div>

        </div>
        
        </div>
    );
};

export default Landing;
